import React from "react";
import { useEffect, useRef } from "react";
import useChat from "../useChat";

const fontType = [
  "Cabin Sketch",
  "Caveat Brush",
  "Courgette",
  "Gochi Hand",
  "Macondo",
  "Nerko One",
  "Patua One",
  "Righteous",
  "Jua",
  "Fredericka the Great",
];

const Home = () => {
  const [setRoomName] = React.useState("");
  const { messages } = useChat("editor");
  const handleRoomNameChange = (event) => {
    setRoomName(event.target.value);
  };
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    console.log(messagesEndRef);
    if (messagesEndRef && messagesEndRef.current /* + other conditions */) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <div className="chat-room-container">
        {/* <img src="./Header 3.png" className="header-img"/> */}

        <div className="image-container">
        <img src="./Header-L.png" className="header-img-left" />
        <img src="./Header-R.png" className="header-img-right" />
        </div>

        <img src="./Background Logo.png" style={{position:"absolute", height:'100%', zIndex:-1}}/>

        <div className="messages-container">
          <ul className="messages-list">
            {messages?.map((message, i) => {
              var num = Math.floor(Math.random() * 10);
              var splitedMessage = message.body.split("-");
              var body = splitedMessage[0] || "";
              var user = "";

              if (splitedMessage?.length > 1) {
                user = splitedMessage[1];
              }

              return (
                <li key={i}>
                  <a href="#">
                    <h2 style={{ fontFamily: fontType[num] }}>{body}</h2>
                    {user != "" && (
                      <label className="user-label">
                        <span>{" - "}</span> <span>{user}</span>
                      </label>
                    )}
                  </a>
                </li>
              );
            })}
            {/* <img src="./left.png" className="bottom-logo" />
            <img src="./right.png" className="bottom-logo-right" /> */}

            <div ref={messagesEndRef} className="scroll-div" />
          </ul>
        </div>
      </div>
      <img src="./Footer.png" className="bottom-image"/>
    </>
  );
};

export default Home;

// <div className="container">
//   <div className="container-inner">
//     {messages.map((message, index) => {
//       return (
//         <div className="sticky-container" key={index}>
//           <div className="sticky-outer">
//             <div className="sticky">
//               <svg width="0" height="0">
//                 <defs>
//                   <clipPath
//                     id="stickyClip"
//                     clipPathUnits="objectBoundingBox"
//                   >
//                     <path
//                       d="M 0 0 Q 0 0.69, 0.03 0.96 0.03 0.96, 1 0.96 Q 0.96 0.69, 0.96 0 0.96 0, 0 0"
//                       strokeLinejoin="round"
//                       strokeLinecap="square"
//                     />
//                   </clipPath>
//                 </defs>
//               </svg>
//               <div className="sticky-content">{message?.body}</div>
//             </div>
//           </div>
//         </div>
//       );
//     })}
//   </div>
// </div>
